import React from "react";
import Layout from '../../components/layout';
import ItemWithfinish from "../../components/itemWithFinish";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { graphql } from 'gatsby';

const NCPaintsMain = ({data}) => (
	<Layout>
		<Row>
			<Col>
				<h1>N C Paints</h1>
			</Col>
		</Row> 
		<hr />
		{/* N C Putty Grey */}
		<ItemWithfinish heading ={"N C Grey Putty"} data={"NC putty is premium grade putty with very good filling and leveling properties. It is very fast drying and smooth for sanding with dry or wet papers . It is suitable for application over polyester fillers, NC or acrylic primers and can be painted over with all paint systems."} image={data.NCPaintsMain.childImageSharp.fluid} package={"1 ltr, 5ltr, 10ltr and 20ltr"} color={"Grey, White, Black, Golden yellow, Oxford blue, Green, Red, N.C clear, Sanding Sealer"} finish={"Smooth & matt."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs."}/>
		<hr />

		{/* N C White */}
		<ItemWithfinish heading ={"N C White"} data={"Applying over primer coat for exterior & interior surface, fast drying & pigment based systems is used over metal alloys, pipes, etc."} image={data.NCPaintsMain.childImageSharp.fluid} package={"1 ltr, 5ltr, 10ltr and 20ltr"} color={"White"} finish={"Smooth & glossy."} dryingTime={"Surface dry : 5-10 minutes, hard dry: 2-3 hrs."}/>
		<hr />

		{/* N C Black */}
		<ItemWithfinish heading ={"N C Black"} data={"Applying over primer coat for exterior & interior surface, fast drying & pigment based systems is used over metal alloys, pipes, etc."} image={data.NCPaintsMain.childImageSharp.fluid} package={"1 ltr, 5ltr, 10ltr and 20ltr"} color={"Black"} finish={"Smooth & glossy."} dryingTime={"Surface dry : 5 minutes, hard dry: 2-3 hrs."}/>
		<hr />

		{/* N C Putty Grey */}
		<ItemWithfinish heading ={"N C Grey Putty"} data={"NC putty is premium grade putty with very good filling and leveling properties. It is very fast drying and smooth for sanding with dry or wet papers . It is suitable for application over polyester fillers, NC or acrylic primers and can be painted over with all paint systems."} image={data.NCPaintsMain.childImageSharp.fluid} package={"	1 ltr, 5ltr, 10ltr and 20ltr"} color={"Grey"} finish={"Smooth & matt."} dryingTime={"Surface dry-5 minutes, hard dry: 2-3 hrs."}/>
	</Layout>
)

export default NCPaintsMain;

export const query = graphql`
query {
	NCPaintsMain: file(relativePath: { eq: "NCPaintsMain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
	}
}
`
	